import './style.css'
import * as THREE from  'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import firefliesVertexShader from './shaders/fireflies/vertex.glsl';
import firefliesFragmentShader from './shaders/fireflies/fragment.glsl';
import portalVertexShader from './shaders/portal/vertex.glsl';
import portalFragmentShader from './shaders/portal/fragment.glsl';


const debugObject = {};
const gui = new dat.GUI({
  width: 400
});

const cursor = new THREE.Vector2();

window.addEventListener('mousemove', (event) => {
  // cursor.x = (event.clientX / sizes.width) - 0.5;
  // cursor.y = - (event.clientY  / sizes.height - 0.5);
  cursor.x = (event.clientX / sizes.width) * 2 - 1;
  cursor.y = - (event.clientY  / sizes.height) * 2 + 1;
});

const canvas = document.querySelector('.webgl');

const scene = new THREE.Scene();

const modelGroup = new THREE.Group();

//loaders
const gltfLoader = new GLTFLoader();

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('/draco/');

//textures

const textureLoader = new THREE.TextureLoader();

const bakedTexture = textureLoader.load('/baked.jpg');
bakedTexture.flipY = false;
bakedTexture.encoding = THREE.sRGBEncoding;

//materials
const bakedMaterial = new THREE.MeshBasicMaterial({map: bakedTexture});

const poleLightMaterial = new THREE.MeshBasicMaterial({color: 0xffffe5});

// model
gltfLoader.load(
  'portal-merged.glb',
  (gltf) => {
    // gltf.scene.traverse((child) => {
    //   child.material = bakedMaterial;
    // })
    const bakedMesh = gltf.scene.children.find((child) => child.name === "baked");
    const portalLightMesh = gltf.scene.children.find((child) => child.name === "portalLight");
    const poleLightAMesh = gltf.scene.children.find((child) => child.name === "poleLightA");
    const poleLightBMesh = gltf.scene.children.find((child) => child.name === "poleLightB");

    bakedMesh.material = bakedMaterial;
    portalLightMesh.material = portalLightMaterial;
    poleLightAMesh.material = poleLightMaterial;
    poleLightBMesh.material = poleLightMaterial;

    modelGroup.add(gltf.scene);
  }
);

// FIREFLIES
const fireFliesGeomtery = new THREE.BufferGeometry();
const fireFliesCount = 30;
const positionArray = new Float32Array(fireFliesCount * 3);

const scaleArray = new Float32Array(fireFliesCount);

for(let i = 0; i < fireFliesCount; i++){
  positionArray[i * 3 + 0] = (Math.random() - 0.5) * 4;
  positionArray[i * 3 + 1] = Math.random() * 1.5;
  positionArray[i * 3 + 2] = (Math.random() - 0.5)* 4;

  scaleArray[i] = Math.random();
}

fireFliesGeomtery.setAttribute('position', new THREE.BufferAttribute(positionArray, 3));
fireFliesGeomtery.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1));

  // fireflies material
const fireFliesMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uPixelRatio: {value: Math.min(window.devicePixelRatio, 2)},
    uSize: {value: 100},
    uTime: {value: 0}
  },
  vertexShader: firefliesVertexShader,
  fragmentShader: firefliesFragmentShader,
  //enable transparency
  transparent: true,
  //blend with the background color
  blending: THREE.AdditiveBlending,
  //background can be hidden
  depthWrite: false
});

gui.add(fireFliesMaterial.uniforms.uSize, 'value').min(0).max(500).step(1).name('firefliesName');


debugObject.portalColorStart = '#000000';
debugObject.portalColorEnd = '#ffffff';

gui
  .addColor(debugObject, 'portalColorStart')
  .onChange(() => {
    portalLightMaterial.uniforms.uColorStart.value.set(debugObject.portalColorStart);
  });

gui
  .addColor(debugObject, 'portalColorEnd')
  .onChange(() => {
    portalLightMaterial.uniforms.uColorEnd.value.set(debugObject.portalColorEnd);
  });
    //portal materials
const portalLightMaterial = new THREE.ShaderMaterial({
  uniforms: {
    uTime: {value: 0},
    uColorStart: {value: new THREE.Color(debugObject.portalColorStart)},
    uColorEnd: {value: new THREE.Color(debugObject.portalColorEnd)}
  },
  vertexShader: portalVertexShader,
  fragmentShader: portalFragmentShader
});

const fireflies =  new THREE.Points(fireFliesGeomtery, fireFliesMaterial);
modelGroup.add(fireflies);

scene.add(modelGroup);

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', ()=>{
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  fireFliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2);
});

const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1 , 100);

camera.position.set(0, 1, 5);
scene.add(camera);

// const controls = new OrbitControls(camera, canvas);
// controls.enableDamping = true;

const renderer = new THREE.WebGLRenderer({
  canvas: canvas
});

renderer.setSize(sizes.width, sizes.height);
renderer.render(scene, camera);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.antialias = true;

debugObject.clearColor = '#201919';
renderer.setClearColor(debugObject.clearColor);

gui.addColor(debugObject, 'clearColor')
  .onChange(() => {
    renderer.setClearColor(debugObject.clearColor);
  });


const clock = new THREE.Clock();

let movementTime = 0;
const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  fireFliesMaterial.uniforms.uTime.value = elapsedTime;
  portalLightMaterial.uniforms.uTime.value = elapsedTime;

  modelGroup.rotation.y = elapsedTime*0.2;

  // controls.update();

  renderer.render(scene, camera);
  window.requestAnimationFrame(tick);
}

tick();
